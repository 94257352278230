import React from "react";
import Lead from "../components/sections/lead";
import Featured from "../components/sections/featured";
import Text from "../components/sections/text";
import Commercial from "../components/sections/commercial";
import commercialAmpmMobile from "../images/commercial-ampm--mobile@1x.jpg";
import commercialAmpmMobileWebp from "../images/commercial-ampm--mobile@1x.webp";
import commercialAmpm from "../images/commercial-ampm@1x.jpg";
import commercialAmpmWebp from "../images/commercial-ampm@1x.webp";
import Feedback from "../components/sections/feedback";
import Footer from "../components/sections/footer";
import leadVideo from "../video/ampm.mp4";
import ampmLogo from "../images/ampm.svg";
import ampmMobileLead from "../images/brand-ampm-bg--mobile@1x.jpg";
import ampmLead from "../images/brand-ampm-bg@1x.jpg";
import {t} from "i18next";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

const Ampm = () => {
  const {t, i18n} = useTranslation();

  return (
    <>
      <Helmet>
        <html lang='ru' />
        <title>{t('lead.seo.ampm')}</title>
      </Helmet>
      <Lead
        title={t('lead.ampm.lead-text')}
        leadVideo={leadVideo}
        leadLogo={ampmLogo}
      />
      <Featured
        imageMobile={ampmMobileLead}
        image={ampmLead}
        sectionClass={`featured--ampm`}
        featuredTitle={t('lead.ampm.second-screen-big')}
        featuredText={t('lead.ampm.second-screen-text')}
      />
      <Text
        titleText={t('lead.ampm.pre-title')}
        bigText={t('lead.ampm.third-screen-big')}
      />
      <Commercial
        mobileImage={commercialAmpmMobile}
        mobileImageWebp={commercialAmpmMobileWebp}
        image={commercialAmpm}
        imageWebp={commercialAmpmWebp}
        commercialText={t('lead.ampm.featured-text')}
        linkText={t('lead.ampm.featured-text-link')}
      />
      <Feedback />
      <Footer />
    </>
  );
};

export default Ampm;
